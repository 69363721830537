body {
  background-color: white;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
}

.mapboxgl-popup {
  max-width: 400px !important;
}